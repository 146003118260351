
import { memo } from 'react';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useMediaQuery } from '@material-ui/core';
import TelegramIcon from '@material-ui/icons/Telegram';
import TwitterIcon from '@material-ui/icons/Twitter';

import OutlinedButton from 'components/UI/Buttons/OutlinedButton';
import DashboardIcon from '@material-ui/icons/Dashboard';

const useStyles = makeStyles(theme => ({
  menuItem: {
    flexDirection: 'row',
    width: 'fit-content',
    minHeight: '100%',
    padding: 0,
    // display:'flex',
    // justifyContent:'center',
    // alignItems:'center'

  }
}));

const TopAppBarMenu = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const menuItemClickHandler = (url) => {
    window.open(url, '_blank');
  };

  return (
    <>
      <ListItem className={classes.menuItem}>
        {/* {isMd &&
          <OutlinedButton onClick={() => menuItemClickHandler('/')} style={{ border: 'none' }}>
            <Typography variant='body2' style={{ fontFamily: 'LULO' }}>ANNOUNCEMENTS</Typography>
          </OutlinedButton>} */}
            <OutlinedButton onClick={() => menuItemClickHandler('https://t.me/GrowYard')} style={{ border: 'none' }}>
            {/* <Typography variant='body2' style={{ fontFamily: 'LULO' }}>Telegram</Typography> */}
            <TelegramIcon fontSize = 'large' style = {{color : 'rgba(254, 173, 15, 1)'}} />
          </OutlinedButton>
          {/* <OutlinedButton onClick={() => menuItemClickHandler('https://twitter.com/GrowYard')} style={{ border: 'none' }}> */}
            {/* <Typography variant='body2' style={{ fontFamily: 'LULO' }}>Telegram</Typography> */}
            {/* <TwitterIcon fontSize = 'large' style = {{color : 'rgba(254, 173, 15, 1)'}} /> */}
          {/* </OutlinedButton> */}
          <OutlinedButton onClick={() => menuItemClickHandler('https://GrowYard.io/')} style={{ border: 'none' }}>
          
          {isMd ?<Typography variant='body2' style={{ fontFamily: 'LULO' }}>Home</Typography> : <DashboardIcon fontSize = 'large' style = {{color : 'rgba(254, 173, 15, 1)'}}/>}
        </OutlinedButton>
       
        
      </ListItem>
    </>
  );
};

export default memo(TopAppBarMenu);
