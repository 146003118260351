
import GitbookIcon from '../../components/Icons/GitbookIcon'
import MediumIcon from '../../components/Icons/MediumIcon'
import TelegramIcon from '../../components/Icons/TelegramIcon'
import TwitterIcon from '../../components/Icons/TwitterIcon'
import EmailIcon from '../../components/Icons/EmailIcon'

const FOOTER_MENUS = [
    // {
    //     id: 'gitbook',
    //     icon: <GitbookIcon />,
    //     url: ''
    // },
    {
        id: 'twitter',
        icon: <TwitterIcon />,
        url: 'https://twitter.com/GrowYardErc'
    },
    {
        id: 'email',
        icon: <EmailIcon />,
        url: 'mailto:tech@GrowYard.io',
    },
    {
        id: 'telegram',
        icon: <TelegramIcon />,
        url: 'https://t.me/GrowYard'
    },
    {
        id: 'medium',
        icon: <MediumIcon />,
        url: 'https://medium.com/@GrowYard',
    }
];

export {
    FOOTER_MENUS
}