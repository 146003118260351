
const PAGES = {
    HOME: {
        title: 'home',
        url: '/'
    },
    AGENCY: {
        title: 'agency',
        url: '/agency'
    },
    DASHBOARD: {
        title: 'Home',
        url: 'http://growyard.io/'
    }
};

export { PAGES }