
import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { useMediaQuery, colors } from '@material-ui/core';
import CircleButton from 'components/UI/Buttons/CircleButton';

import Logo from 'components/Logo';
import { PAGES } from 'utils/links/pages';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1)
    },
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    },
  },
  height: {
    height: '100%'
  },
}));

const LogoWithTitle = ({ history, titleVariant, className }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const onClickHandler = () => {
    console.log('aj : ******')
    history.push(PAGES.HOME.url);
  }

  return (
    <div className={clsx(classes.root, className)} onClick={onClickHandler}>
      <Logo height={50} width={'100%'} />
      {/* <CircleButton style={{ display: 'flex', backgroundColor: '#292C40', marginRight: 30 }} onClick={onClickHandler} icon={<Logo height={80} width={145} />} /> */}
      <Typography color='textPrimary' style={{ fontFamily: 'Montserrat Alternates', fontSize : 24 }} variant={'body1'}>
        {isMd && 'row Yard '}
      </Typography>
    </div>
  );
};

export default withRouter(LogoWithTitle);
